import { useState, useEffect, useRef, useContext } from 'react'
import { AppContext } from 'library/context'
import { useRouter } from 'next/router'
import Head from 'next/head'
import Link from 'components/link'
import { useWindowSize } from 'library/hooks/useWindowSize'
import { trimCharacters, getCleanUrlPath } from 'library/helpers/utilities'

import { Workbench } from '@contentful/f36-workbench'
import { Heading, IconButton, Modal } from '@contentful/f36-components'
import {  
  ListBulletedIcon,
  UsersIcon,
  SettingsIcon,
  SearchIcon
} from '@contentful/f36-icons'

import MainRulebookNav from 'components/main-rulebook-nav'
import ArmyRulesNav from 'components/army-rules-nav'
import Preferences from 'components/preferences'
import SearchForm from 'components/search-form'
import Footer from 'components/footer'
import Notice from 'components/notice'
import QuickEdit from 'components/quick-edit'
import PageActions from 'components/page-actions'

// TEMP
import { Note } from '@contentful/f36-components'

const Wrapper = props => {

  // Get context data
  const { classView } = useContext(AppContext)

  // Set state for header items
  const [showToc, setShowToc] = useState(false)
  const [showArmy, setShowArmy] = useState(false)
  const [showPrefs, setShowPrefs] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  // Get routing object for pathing
  const router = useRouter()

  // Use hooks to watch for window resize/scroll
  const [windowWidth] = useWindowSize()

  // Clear/hide search on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete')
    }
  }, [])

  // Close all modals and jump to top of page on route
  const handleRouteChange = () => {
    setShowToc(false)
    setShowArmy(false)
    setShowPrefs(false)
    setShowSearch(false)
    if (document && document.getElementById('main-content')) document.getElementById('main-content').scrollTop = 0
  }

  // Site Name
  let site = process.env.NEXT_PUBLIC_SITE_TITLE

  // Build header actions/navigation
  const actions = (
    <div className="main-actions">
      <IconButton
        className="nav-icon ghost-button"
        icon={<ListBulletedIcon variant="primary" size="large" />}
        onClick={() => setShowToc(true)}
      />
      {process.env.NEXT_PUBLIC_ARMIES_ENABLED ?
        <IconButton
          className="nav-icon ghost-button"
          icon={<UsersIcon variant="primary" size="large" />}
          onClick={() => setShowArmy(true)}
        />
      : null }
      <IconButton
        className="nav-icon ghost-button"
        icon={<SettingsIcon variant="primary" size="medium" />}
        onClick={() => setShowPrefs(true)}
      />
      <IconButton
        className="nav-icon ghost-button"
        icon={<SearchIcon variant="primary" size="large" />}
        onClick={() => setShowSearch(true)}
      />
    </div>
  )

  // Build TOC modal
  const tocLabel = process.env.NEXT_PUBLIC_TOC_LABEL ? process.env.NEXT_PUBLIC_TOC_LABEL : 'Table of Contents'
  const tocModal = (
    <Modal
      isShown={showToc}
      onClose={() => setShowToc(false)}
      title={tocLabel}
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <MainRulebookNav />
    </Modal>
  )

  // Build army index modal
  const armiesLabel = process.env.NEXT_PUBLIC_ARMIES_LABEL ? process.env.NEXT_PUBLIC_ARMIES_LABEL : 'Army Rules'
  const armiesModal = (
    <Modal
      isShown={showArmy}
      onClose={() => setShowArmy(false)}
      title={armiesLabel}
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <ArmyRulesNav />
    </Modal>
  )

  // Build config modal
  const prefsModal = (
    <Modal
      isShown={showPrefs}
      onClose={() => setShowPrefs(false)}
      title="Preferences"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
    >
      <Preferences />
    </Modal>
  )

  // Build search modal
  const inputRef = useRef(null)
  const searchModal = (
    <Modal
      isShown={showSearch}
      onClose={() => setShowSearch(false)}
      title="Search"
      position="center"
      size={windowWidth >= 600 ? "large" : "fullscreen"}
      overlayProps={{ "className": "modal-overlay" }}
      initialFocusRef={inputRef}
    >
      <SearchForm inputRef={inputRef} />
    </Modal>
  )

  // Build title
  const title = props.title ? `${props.title} | ${site}` : site

  // Build notice
  let notice
  if (props.notice) {
    notice = props.notice.map(item => <Notice key={item.sys.id} entry={item} />)
  }

  // Create canonical tag
  const canonical = `${process.env.NEXT_PUBLIC_PROD_URL}${getCleanUrlPath(router.asPath)}`

  // CUSTOM: Append text for tow
  if (process.env.NEXT_PUBLIC_TAG === 'tow') site = `${site} Online Rules Index`

  // Minimal view source
  const source = <p className="minimal-source">Source: <a href={process.env.NEXT_PUBLIC_PROD_URL} target="_blank">{site}</a></p>

  // Index class
  const indexClass = `${process.env.NEXT_PUBLIC_TAG}-index`

  // TEMP
  let wapNotice
  if (process.env.NEXT_PUBLIC_TAG === 'wap') wapNotice = (
    <Note variant="warning" className="global-notice">
      The index is currently displaying <strong>version 2.2</strong>. The index will be updated to version 2.3 at a future date.
    </Note>
  )

  return (
    <>
      <Head>
        <title>{title}</title>
        {props.description && <meta name="description" content={trimCharacters(props.description, 155, '...')} />}
        {canonical && <link rel="canonical" href={canonical} />}
      </Head>
      <Workbench className={`${classView} ${indexClass} ${props.className ? props.className : ''}`}>
        <Workbench.Header
          className="main-header"
          onBack={router.back}
          title={<Heading><Link href="/">{site}</Link></Heading>}
          actions={actions}
        />
        <Workbench.Content
          type="default"
          id="main-content"
          className="main-content"
        >

          {source}
          {wapNotice}
          {notice}
          {props.title && !props.metaOnly ? <h1 className="page-title">{props.title}<PageActions includeQuery={props.includeQuery} /></h1> : ''}
          <QuickEdit entryId={props.entryId ? props.entryId : null} />
          {props.children}

        </Workbench.Content>
      </Workbench>
      <Footer />
      {tocModal}
      {process.env.NEXT_PUBLIC_ARMIES_ENABLED && armiesModal}
      {prefsModal}
      {searchModal}
  </>
  )
}

export default Wrapper